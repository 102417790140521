import gql from 'graphql-tag'

export const PRODUCTS_QUERY = gql`
  query ProductQuery {
    products @client {
      id
      title
      sku
      description
      price
      vat
      thumbnail_url
      category_id
    }
  }
`

/*

Example :

    """
    {
      "data": {
        "cart": {
          "total": 825,
          "order_items": [
            {
              "product": {
                "id": "muesli_001",
                "title": "Muesli aux Fruit des Bois",
                "sku": "muesli_001",
                "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
                "price": 825,
                "vat": 550,
                "thumbnail_url": "https://images.prismic.io/cryptruserui/85ff5fc2-c62d-4658-9a63-163f3bf46499_muesli_fruit_des_bois.jpeg?auto=compress,format",
                "category_id": "brunch_base",
                "__typename": "Product"
              },
              "quantity": 1,
              "__typename": "OrderItem"
            }
          ],
          "__typename": "Cart"
        }
      },
      "loading": false,
      "networkStatus": 7,
      "stale": false
    }
    """
*/
export const CART_QUERY = gql`
  query CartQuery {
    cart @client {
      total
      order_items {
        product {
          id
          title
          sku
          description
          price
          vat
          thumbnail_url
          category_id
        }
        quantity
      }
    }
  }
`

export const CHECKOUT_QUERY = gql`
  query CheckoutQuery {
    checkout @client {
      id
      withdrawal_hour_start
      withdrawal_hour_end
      address
    }
  }
`

export const CATEGORIES_QUERY = gql`
  query CategoriesQuery {
    categories @client {
      id
      title
      description
    }
  }
`
