import { Cart, OrderItem, Product } from '../types'

const createOrderItem = (cart: Cart, product: Product): Cart => {
  return {
    ...cart,
    order_items: [
      ...cart.order_items,
      {
        product: product,
        quantity: 1,
        total: product.price,
        __typename: 'OrderItem',
      },
    ],
    total: cart.total + product.price,
  }
}

const updateOrderItem = (cart: Cart, orderItem: OrderItem, direction: number): Cart => {
  const baseOrderItems: Array<OrderItem> = cart.order_items.filter(
    (item: OrderItem) => item !== orderItem,
  )

  const newOrderItems =
    orderItem.quantity === 1 && direction === -1
      ? baseOrderItems
      : [
          ...baseOrderItems,
          {
            ...orderItem,
            quantity: orderItem.quantity + direction,
            total: orderItem.total + orderItem.product.price * direction,
          },
        ]

  return {
    ...cart,
    order_items: newOrderItems,
    total: cart.total + orderItem.product.price * direction,
  }
}

export const pushProduct = (cart: Cart, product: Product): Cart => {
  const orderItem: OrderItem = cart.order_items.find(
    (item: OrderItem) => item.product.id === product.id,
  )

  return orderItem === undefined
    ? createOrderItem(cart, product)
    : updateOrderItem(cart, orderItem, +1)
}

export const removeProduct = (cart: Cart, product: Product): Cart => {
  const orderItem: OrderItem = cart.order_items.find(
    (item: OrderItem) => item.product.id === product.id,
  )

  return orderItem === undefined ? cart : updateOrderItem(cart, orderItem, -1)
}
