module.exports = [
  {
    id: 'patisserie',
    title: 'Pâtisseries',
    description: 'Lorem ipsum',
    __typename: 'Categories',
  },
  {
    id: 'truffe',
    title: 'Truffes',
    description: 'Lorem ipsum',
    __typename: 'Categories',
  },
  {
    id: 'tablette',
    title: 'Tablettes',
    description: 'Lorem ipsum',
    __typename: 'Categories',
  },
]
