import { ApolloClient } from 'apollo-client'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import fetch from 'node-fetch'

import defaults from './defaults'
import typeDefs from './typeDefs'
import resolvers from './resolvers'

const apiGraphqlUri = 'https://map-hasura-dev.herokuapp.com/v1/graphql'

const cache = new InMemoryCache()

cache.writeData({
  data: defaults,
})

// https://map-hasura-dev.herokuapp.com/

const localStateClient = new ApolloClient({
  cache: cache,
  connectToDevTools: true,
  // Didn't find for now how to do without a link ...
  link: new HttpLink({
    uri: apiGraphqlUri,
    fetch: fetch,
  }),
  typeDefs: typeDefs,
  resolvers: resolvers,
})

export default localStateClient
