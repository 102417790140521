import { CART_QUERY, CHECKOUT_QUERY, CATEGORIES_QUERY } from '../graphql/queries'
import PRODUCTS from '../../data/catalogue'
import CHECKOUT from '../../data/cart'
import CATEGORIES from '../../data/categories'
import { pushProduct, removeProduct } from '../utils'

const resolvers = {
  Mutation: {
    /*
    mutation CartMutation($sku: String!) {
      addProductToCart(sku: $sku) @client {
        total
        order_items {
          quantity
          product {
            id
            title
            sku
            description
            price
            vat
            thumbnail_url
            category_id
          }
        }
      }
    }


    {"sku": "muesli_001"}
    */
    setCategories: (_root, args, { cache }) => {
      const categories = cache.readQuery({ query: CATEGORIES_QUERY })
      const categoriesIntro = CATEGORIES.find((categories) => categories.id === args.id)

      if (categoriesIntro === undefined) {
        return categories
      }

      cache.writeData({
        data: categoriesIntro,
      })

      return categoriesIntro
    },

    setWithdrawal: (_root, args, { cache }) => {
      const checkout = cache.readQuery({ query: CHECKOUT_QUERY })
      const checkoutDetails = CHECKOUT.find((checkout) => checkout.id === args.id)

      if (checkoutDetails === undefined) {
        return checkout
      }

      cache.writeData({
        data: checkoutDetails,
      })

      return checkoutDetails
    },

    addProductToCart: (_root, args, { cache }) => {
      const { cart } = cache.readQuery({ query: CART_QUERY })

      const productFound = PRODUCTS.find((product) => product.sku === args.sku)

      if (productFound === undefined) {
        return cart
      }

      const newCart = pushProduct(cart, productFound)

      cache.writeData({
        data: {
          cart: newCart,
        },
      })
      return newCart
    },

    removeProductToCart: (_root, args, { cache }) => {
      const { cart } = cache.readQuery({ query: CART_QUERY })
      const productFound = PRODUCTS.find((product) => product.sku === args.sku)

      if (productFound === undefined) {
        return cart
      }

      const newCart = removeProduct(cart, productFound)
      cache.writeData({
        data: {
          cart: newCart,
        },
      })

      return newCart
    },
  },
}

export default resolvers
