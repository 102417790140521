module.exports = [
  {
    id: 'macaron_001',
    sku: 'macaron_001',
    price: 2500,
    vat: 550,
    title: 'Macarons aux amandes croquantes',
    description: 'Chocolat noir, amandes, cacao',
    category_id: 'patisserie',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/4ec73d4f-591a-488c-a955-700585aff2a5_diana-akhmetianova-4gyd_1A7AFs-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'cake_001',
    sku: 'cake_001',
    price: 935,
    vat: 550,
    title: 'Gâteau moelleux au chocolat noir',
    description: 'Chocolat noir',
    category_id: 'patisserie',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/baf10135-d3a1-4e13-a4a1-1294f84f3c6d_gaelle-marcel-3o2j1uvUfAM-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'granola_001',
    sku: 'granola_001',
    price: 825,
    vat: 550,
    title: 'Granola au beurre de cacahuètes',
    description: "Flocons d'avoine, chocolat noir, beurre de cacahuètes",
    category_id: 'patisserie',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/eee2c1ac-af29-4cdd-a448-d312b75c1126_taylor-kiser-Z4zLE_8VZTc-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'muffin_001',
    sku: 'muffin_001',
    price: 900,
    vat: 550,
    title: "Muffin au chocolat noir garni d'une cerise",
    description: 'Chocolat noir, crème chantilly vanille, cerise',
    category_id: 'patisserie',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/1b0dc67f-015e-4277-afff-8e18535b4be1_michaela-baum-VnM6_liIRJ0-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'bread_001',
    sku: 'bread_001',
    price: 1500,
    vat: 550,
    title: 'Banana bread au chocolat',
    description: 'Chocolat au lait, bananes',
    category_id: 'patisserie',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/0187a43e-40d8-40ed-833d-8706c467e0c3_tatiana-rodriguez-v0U044U_i8w-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'cookie_001',
    sku: 'cookie_001',
    price: 850,
    vat: 550,
    title: 'Cookies aux pépites de chocolat noir',
    description: 'Chocolat noir',
    category_id: 'patisserie',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/1fdfbac3-eb58-4f24-8ba1-f3a71bd2cfc6_mae-mu-kID9sxbJ3BQ-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'fondant_001',
    sku: 'fondant_001',
    price: 1325,
    vat: 550,
    title: 'Fondant au trois chocolats',
    description: 'Chocolat blanc, chocolat au lait, chocolat noir',
    category_id: 'patisserie',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/7c15dbc7-0767-4787-a7b7-0183e064b22f_heather-barnes-xatIo9Ksfb0-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'brownie_001',
    sku: 'brownie_001',
    price: 1480,
    vat: 550,
    title: 'Brownie au chocolat et aux framboises',
    description: 'Chocolat noir, framboises',
    category_id: 'patisserie',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/a3c16314-1df2-4fa6-82ba-233546c32057_shania-pinnata-n8psEOtD4Ro-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'truffe_001',
    sku: 'truffe_001',
    price: 1500,
    vat: 550,
    title: 'Truffes croquantes aux pistaches',
    description: 'Chocolat noir, pistaches',
    category_id: 'truffe',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/c4fc5126-9915-4a15-88e3-10ec4f5875cd_tatiana-byzova-rNueZu9gI14-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'truffe_002',
    sku: 'truffe_002',
    price: 1450,
    vat: 550,
    title: 'Truffes chocolat nature',
    description: 'Ganache moussée, cacao',
    category_id: 'truffe',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/862e217a-d3b0-4a5c-b915-d4a606557518_marc-markstein-D5omwuxNaHw-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'truffe_003',
    sku: 'truffe_003',
    price: 1500,
    vat: 550,
    title: 'Truffes fondante aux noisettes',
    description: 'Chocolat noir, noisettes',
    category_id: 'truffe',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/e9b4ed3f-de2a-4edb-97f6-ac0b8b12a081_merve-aydin-jD2dIC2IZME-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'truffe_004',
    sku: 'truffe_004',
    price: 1450,
    vat: 550,
    title: 'Truffes au chocolat et café',
    description: 'Chocolat noir, café noir, cacao',
    category_id: 'truffe',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/661807c0-477f-469f-aa16-ff62ad8bcc5c_nico-wijaya-Oq2hMv3kchU-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'truffe_005',
    sku: 'truffe_005',
    price: 1600,
    vat: 550,
    title: 'Truffes aux framboises',
    description: 'Chocolat mi-amer, framboises, cacao',
    category_id: 'truffe',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/dafe0edb-fe88-4c8a-9520-09b3d3028739_joanna-kosinska-4dnG4q3kxdg-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'tablette_001',
    sku: 'tablette_001',
    price: 1200,
    vat: 550,
    title: 'Tablette de chocolat noir aux noisettes',
    description: 'Noisettes caramélisées entières, chocolat noir.',
    category_id: 'tablette',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/4a358683-6e43-4726-8135-4c3074c2465b_foodism360-axDXGOF_lJY-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'tablette_002',
    sku: 'tablette_002',
    price: 1200,
    vat: 550,
    title: 'Tablette de chocolat blanc aux noisettes',
    description: 'Chocolat blanc, noisettes.',
    category_id: 'tablette',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/fbd6f90b-2625-4159-8dff-f47d031b7ba1_emy-Rx3QSrG1coc-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'tablette_003',
    sku: 'tablette_003',
    price: 700,
    vat: 550,
    title: 'Tablette de chocolat noir classique',
    description: 'Chocolat noir, chocolat au lait',
    category_id: 'tablette',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/60d3b8b8-44bb-4924-a8e8-8640f6435541_julia-androshchuk-vLhJglizAeU-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'tablette_004',
    sku: 'tablette_004',
    price: 1000,
    vat: 550,
    title: 'Tablette de chocolat noir au coulis de framboise',
    description: 'Chocolat noir, framboises',
    category_id: 'tablette',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/be8d875c-7a71-4f05-8aa3-5b23f4f6c154_michele-blackwell-evRB-x0TJkM-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'tablette_005',
    sku: 'tablette_005',
    price: 700,
    vat: 550,
    title: 'Tablette de chocolat au lait',
    description: 'Chocolat au lait',
    category_id: 'tablette',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/1c4735b8-d5aa-4bb0-9eac-3fec2659042b_nathana-reboucas-D-77eAExH_E-unsplash.jpg?auto=compress,format',
    __typename: 'Product',
  },
]
