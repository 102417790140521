// import Catalogue from '../../data/catalogue'

const Catalogue = [
  {
    id: 'muesli_001',
    sku: 'muesli_001',
    price: 825,
    vat: 550,
    title: 'Muesli aux Fruit des Bois',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
    category_id: 'brunch_base',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/85ff5fc2-c62d-4658-9a63-163f3bf46499_muesli_fruit_des_bois.jpeg?auto=compress,format',
    __typename: 'Product',
  },
  {
    id: 'pancake_001',
    sku: 'pancake_001',
    price: 825,
    vat: 550,
    title: 'Montagne de Pancakes aux Fraise',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
    category_id: 'brunch_base',
    thumbnail_url:
      'https://images.prismic.io/cryptruserui/e7967192-9887-486a-95fd-38435548940d_pancake_fraise.jpeg?auto=compress,format',
    __typename: 'Product',
  },
]

const defaults = {
  products: Catalogue,
  cart: {
    __typename: 'Cart',
    order_items: [],
    total: 0,
  },
  checkout: {
    __typename: 'Checkout',
    id: 'commande_001',
    withdrawal_hour_start: '19h',
    withdrawal_hour_end: '20h',
    address: 'Boulangerie Louise de Halluin',
  },
  categories: {
    __typename: 'Categories',
    id: 'patisserie',
    title: 'Pâtisserie',
    description: 'Lorem ipsum',
  },
}

export default defaults
