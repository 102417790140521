const typeDefs = `
  type Product {
    title: String!
    sku: String!
    description: String!
    price: Int!
    vat: Int!
    thumbnail_url: String!
    category_id: String! 
  }

  type OrderItem implements Node @dontInfer {
    product: Product!
    quantity: Int!
  }

  type Cart implements Node @dontInfer {
    order_items: [OrderItem]
    total: Int!
  }

  type Checkout {
    id: String!
    withdrawal_hour_start: String!
    withdrawal_hour_end: String!
    address: String!
  }

  type Categories {
    id: String!
    title: String!
    description: String!
  }
`

export default typeDefs
